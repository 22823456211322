import { Helmet } from 'react-helmet'
import React from 'react'

export default function LandingPageHelmet() {
  return (
    <Helmet>
    <title>Budget Even</title>
    <meta name='description' content='Welcome to the Budget Even landing page.  Budget Even offers a simple and correct way to plan
    your monthly budget.  You can create and customize your own individual budget groups and budget line items.  Track your expenses by
    adding transactions manually, or sign up for a premium account to automatically download transactions from your credit cards and other
    financial accounts.  Premium users can also simplify their budget and transaction tracking by creating Auto-track rules.  Simply select a 
    transaction, select a budget group and budget line item, and save the rule.  With a rule saved, you can simply click to have our software do
    the heavy lifting and transaction tracking for you.  Further, you can keep track of your net worth, plan your cash flow, and analyze your budget.  
    Budget Even offers a number of tools to help you keep your Budget Even, and pave the way to sustainable monthly savings.  Budget Even is also
    available on the App Store, and the Google Play Store for budgeting on the go. '>
    </meta>
    <meta name='keywords' content='Budget Even, Monthly Budget Planner, Cash flow planning, Net worth tracking, Transaction Tracking'/>
  </Helmet>
  )
}
