import BudgetContextTwoProvider from "contexts/BudgetContextTwo";
import { budgetTheme } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import AppRoutes from "./routes";
import { StyledEngineProvider } from "@mui/material/styles";
import { Toaster } from "react-hot-toast";

import { testMode } from "utils/GlobalConstans";
import { AltDataProvider } from "contexts/AltDataContext";
import LandingPageHelmet from "helmets/LandingPageHelmet";

import { AuthenticationProvider } from "contexts/AuthenticationContext";
import { NetWorthProvider } from "contexts/NetWorthContext";
import { CashFlowProvider } from "contexts/CashFlowContext";



//maybe make a floating calculator on web so you can tell $12k- what etc.
//check the account aggregation dates and if they aren't up to date, refresh accounts in the background
//revisit the onboarding steps and the differences between mobile and web
//revisit selecting a custom line item when onboarding
//keep patrick@budgeteven and then create a separate test account
//add more loading messages to the web onboarding
//revisit when tracking transactions that it scans similar payees to track
//make a better loading screen, maybe with some rive animations
//fix the description column on the cash flow web

//const stripePromise = loadStripe('pk_live_9Jl79qB8plEOK32AKBCYWcVc00bwjBcger')




const stripePromise = testMode ? loadStripe('pk_test_9mYyFvIKISb1MhUlTf4M0bVn00LnEcwL5p') : loadStripe('pk_live_9Jl79qB8plEOK32AKBCYWcVc00bwjBcger')


//*****REVIEW******* */
//The userObject created with web and mobile for handling the tutorials
//And the steps

//*****CASEY OLD *****/
//9ECGMuSbWJYZuXFojEhoa8BVbR83
//5024526242

//******NEW TEST ACCOUNTS***** */
//p_live_semi_test@budgeteven.com
//p_live_auto_test@budgeteven.com
//m_live_semi_test@budgeteven.com


//******CHECKS***** */
//make sure test mode is off OnBoardCheckout.js UpdatedCheckOutInterior.js
//make sure stripe is live

/*
Bugs and Updates

-Update the net worth page to the new logo system
-Update the analytics
-Update the blog
-Delete once or au
-Make an option for new transactions to delete once, or auto-delete

*/


//bugs
//Might need to automatically change the spent value to reflect the transactions recorded (happened on ATM transfers)
//Make an option for new transactions to delete once, or auto-delete


//TO DO
//Make a separate route for upgrading and downgrading subscriptions
//Review the Stripe cancellation process
//Update the loading animations and stuff

const App = () => {

  // const notAuthenticatedRoutes = useRoutes(nonProtectedRoutes)
  // const authenticatedRoutes = useRoutes(protectedRoutes)
  //const allPages = useRoutes(routes);
  const appTheme = budgetTheme({theme: "light",direction: "ltr",responsiveFontSizes: true})
  const toasterOptions = {style: {fontWeight: 500,fontFamily: "'Montserrat', sans-serif"}}

  return <StyledEngineProvider injectFirst>
      <LandingPageHelmet />
      <AuthenticationProvider>


      <ThemeProvider theme={appTheme}>
          <BudgetContextTwoProvider>
          <AltDataProvider>
            <NetWorthProvider>
              <CashFlowProvider>
              <Elements stripe={stripePromise}>
                <CssBaseline />
                  <Toaster toastOptions={toasterOptions} />
           
                  <AppRoutes />     
                </Elements>
                </CashFlowProvider>
                </NetWorthProvider>
                </AltDataProvider>
          </BudgetContextTwoProvider>
        </ThemeProvider>
        </AuthenticationProvider>
    </StyledEngineProvider>

   
};

export default App;