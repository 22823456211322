import NumberFormat from 'react-number-format';


export const monthStringsList =['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']


export function emailCustomerSupport (){

    window.open('mailto:help@budgeteven.com?subject=Help')
   
   
}

export function determinePodsContainerHeight(isSmall,height){
  if(isSmall){

    if(height > 500 && height < 600){
      return  'auto';
    }

    if(height > 600 && height < 650){
      return 'auto';
    }
    if(height > 650 && height < 700){
      return 'auto';
    }

    if(height > 700 && height < 800){
      return 440;
    }

    if(height > 800 && height < 900){
      return 'auto';
    }

  }

  else {
    return 'auto';
  }
}


export function NumberFormatCustomHelper(props){
  const {inputRef, onChange, ...other } = props;
  return(
    <NumberFormat {...other} getInputRef={inputRef}
                  onValueChange={(values) => {
                    onChange({
                      target: {
                        //name: props.name,
                        value: values.value
                      }
                    })
                  }} 
                  thousandSeparator
                  isNumericString
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix='$'> 
    </NumberFormat>
  )
}



   export function checkCodeAction(code){

    switch(code){
      case 101:
        return {message: 'There is currently a problem with the connection to this financial institution and it is being worked on. Please try again later',fixable:false}
        case 102:
          return {message:'We are having an issue connecting to your bank right now.  Please wait a moment and try again', fixable:false}
          case 103:
            return {message: 'Invalid username or password.  If you continue to receive this error, please log into your bank\'s website to ensure access, then come back and try again',
          fixable: true}
          case 104:
            return {message:'There was a problem connecting to the institution.', fixable:false}
            case 105:
              return {message: 'There is currently a problem with the connection to this financial institution and it is being worked on.  Please try again later.',
            fixable:false}
            case 106:
              return {message: 'There is currently a problem with the connection to this financial institution and it is being worked on.  Please try again later.', fixable:false}
              case 108:
                return {message: 'Your bank requires an action on their website.  Please log into your bank\'s website to complete the required actions, and come back to try again.',
              fixable: false}
              case 109:
                return {message: 'Your bank requires an action on their website.  Please log into your bank\'s website to complete the required actions, and come back to try again.',
              fixable: false}
              case 185:
                return {message: 'One of your security questions has expired and needs to be answered', fixable: true}
                case 187:
                return {message: 'The answer to  your security question is incorrect.  If you continue to receive this error, please log into your bank\'s website to ensure access, then come back and try again',
                 fixable: true}
                 case 325:
                  return {message:'Your data is currently being aggregated and is inaccessible at the moment.  Please wait several minutes and keep trying.', fixable: false}
                  case 580:
                    return {message: 'Website connectivity issue, please try again later.', fixable: false}
                    case 910:
                      return {message:'The connection is currently down and being worked on.  Please check back in 3-5 days.', fixable:false}
                      case 913:
                        return {message: 'Account status is closed.', fixable: false}
                        case 914:
                          return {message:'No open or active account exists for the login provided.', fixable:false}
                          case 915:
                            return {message:'Gathering additional authentication information in order to proceed with fix.', fixable:false}
                            case 916:
                            return {message:'Gathering additional authentication information in order to proceed with fix. ', fixable:false}
                            case 920:
                            return {message:'Your financial institution is currently not supported.  Inform your institution they should contact institutions@finicity.com to resolve the issue.', fixable:false}
                            case 921:
                              return {message:'Your financial institution is currently not supported.  Inform your institution they should contact institutions@finicity.com to resolve the issue.', fixable:false}
                              case 922:
                                return {message:'Your financial institution is currently not supported.  Inform your institution they should contact institutions@finicity.com to resolve the issue.', fixable:false}
                                case 923:
                                  return {message:'Your financial institution is currently not supported.  Inform your institution they should contact institutions@finicity.com to resolve the issue.', fixable:false}
                                  case 924:
                                    return {message:'Your financial institution is currently not supported.  Inform your institution they should contact institutions@finicity.com to resolve the issue.', fixable:false}
                                    case 925:
                                      return {message:'Your financial institution is currently not supported.  Inform your institution they should contact institutions@finicity.com to resolve the issue.', fixable:false}
                                      case 926:
                                        return {message:'Your financial institution is currently not supported.  Inform your institution they should contact institutions@finicity.com to resolve the issue.', fixable:false}
                                        case 927:
                                          return {message:'Your financial institution is currently not supported.  Inform your institution they should contact institutions@finicity.com to resolve the issue.', fixable:false}
                                          case 928:
                                            return {message:'Your financial institution is currently not supported.  Inform your institution they should contact institutions@finicity.com to resolve the issue.', fixable:false}
                                            case 929:
                                              return {message:'Your financial institution is currently not supported.  Inform your institution they should contact institutions@finicity.com to resolve the issue.', fixable:false}
                                              case 931:
                                                return {message:'Customer must refresh accounts and answer OTP for updated data.', fixable:false}
                                                case 938:
                                                  return {message:'For security reasons, we could not connect to your account at this time.', fixable:false}
                                                  case 940:
                                                    return {message:'We are having issues connecting to your bank and are working to resolve it.', fixable:false}
                                                    case 945:
                                                      return {message:'The account is migrated but not consented to open authorization.', fixable: true}
                                                      case 946:
                                                      return {message:'This institution returned no record for the request.', fixable: true}
                                                      case 947:
                                                        return {message:'Your token is invalid or has expired.  Please re-authenticate', fixable: true}
                                                        case 948:
                                                          return {message:'This institution is in migration status.  Please re-authenticate', fixable: true}
                                                          case 949:
                                                            return {message:'Accounts are in error status and cannot be recovered.  Please delete these accounts.', fixable: false}
                                                            case 950:
                                                              return {message:'This bank is not supportedfor the request.', fixable: false}
                                                            
         

              default:
                return {message: 'An unexpected error has occurred connecting to this institution.  Please try again and if the problem persists, contact customer service.',
              fixable: false}



    }
  }




export const updatedGetLastMonthAndYearString = () => {
    let date = new Date();
    let currentMonth = date.getMonth();
    currentMonth = monthStringsList[currentMonth]
    let year = date.getFullYear();
    let lastMonth = ''
    
  

    switch(currentMonth){
        case 'January':
            lastMonth = 'December'
            year = parseInt(year) - 1
            break;

        case 'February':
            lastMonth = 'January'
            break;

            case 'March':
               
                lastMonth = 'February'
              
                break;

                case 'April':
                    lastMonth = 'March'
                    break;

                    case 'May':
                        lastMonth = 'April'
                        break;

                        case 'June':
                            lastMonth = 'May'
                            break;

                            case 'July':
                                lastMonth = 'June'
                                break;

                                case 'August':
                                    lastMonth = 'July'
                                    break;

                                    case 'September':
                                        lastMonth = 'August'
                                        break;

                                        case 'October':
                                            lastMonth = 'September'
                                            break;

                                            case 'November':
                                                lastMonth = 'October'
                                                break;

                                                case 'December':
                                                    lastMonth = 'November'
                                                    break;

                                                    default:
                                                        lastMonth = currentMonth
                                                        break;

      


    }

    let concat = String(lastMonth) + ' ' + String(year);
 
    return concat
}


export const getLastMonthString = (month, year) => {
    let lastMonth = ''
  

    let currentMonth = month.trim()
  
  

    switch(currentMonth){
        case 'January':
            lastMonth = 'December'
            year = parseInt(year) - 1
            break;

        case 'February':
            lastMonth = 'January'
            break;

            case 'March':
               
                lastMonth = 'February'
              
                break;

                case 'April':
                    lastMonth = 'March'
                    break;

                    case 'May':
                        lastMonth = 'April'
                        break;

                        case 'June':
                            lastMonth = 'May'
                            break;

                            case 'July':
                                lastMonth = 'June'
                                break;

                                case 'August':
                                    lastMonth = 'July'
                                    break;

                                    case 'September':
                                        lastMonth = 'August'
                                        break;

                                        case 'October':
                                            lastMonth = 'September'
                                            break;

                                            case 'November':
                                                lastMonth = 'October'
                                                break;

                                                case 'December':
                                                    lastMonth = 'November'
                                                    break;

                                                    default:
                                                        lastMonth = currentMonth
                                                        break;

      


    }

    let concat = String(lastMonth) + ' ' + String(year);
 
    return concat
}

export function getMonthString(value){
  switch(value){
    case '01':
      return 'January'
      case '02':
        return 'February'
        case '03':
          return 'March'
          case '04':
            return 'April'
            case '05':
              return 'May'
              case '06':
                return 'June'
                case '07':
                  return 'July'
                  case '08':
                    return 'August'
                    case '09':
                      return 'September'
                      case '10':
                        return 'October'
                        case '11':
                          return 'November'
                          case '12':
                            return 'December'
                            default:
                              return 'January'
  }
}


export const getLastMonthStartAndEndEpoch = () => {

    let todayEpoch = Math.floor(Date.now());
    let todayFormattedString = new Date(todayEpoch).toISOString();
    let currentYear = new Date().getFullYear();
    let currentMonth = todayFormattedString.slice(5,7);

    let updatedPreviousMonthString = '01'
    switch(currentMonth){
      case '01':
        updatedPreviousMonthString = '12'
        break;
        
        case'02':
        updatedPreviousMonthString = '01'
        break;
        case '03':
          updatedPreviousMonthString = '02'
          break;

          case '04':
            updatedPreviousMonthString = '03'
            break;

            case '05':
              updatedPreviousMonthString = '04'
              break;

              case '06':
                updatedPreviousMonthString = '05'
                break;

                case '07':
                  updatedPreviousMonthString = '06'
                  break;

                  case '08':
                    updatedPreviousMonthString = '07'
                    break;

                    case '09':
                      updatedPreviousMonthString = '08'
                      break;

                      case '10':
                        updatedPreviousMonthString = '09'
                        break;

                        case '11':
                          updatedPreviousMonthString = '10'
                          break;
                          
                          case '12':
                            updatedPreviousMonthString = '11'
                            break;

                            default:
                              return null;


    }



    if(currentMonth === '01'){
    
      currentYear = parseInt(currentYear) - parseInt(1);
      }

    

    let monthStartString = String(currentYear) + '-' + String(updatedPreviousMonthString) + '-' + '01';
    let totalDaysOfMonth;


    switch(updatedPreviousMonthString){
        case '01':
            totalDaysOfMonth = '31';
            break;
            case '02':
                totalDaysOfMonth = '28';
                break;
                case '03':
                    totalDaysOfMonth = '31';
                    break;
                    case '04':
                        totalDaysOfMonth = '30';
                        break;

                     case '05':
                         totalDaysOfMonth = '31';
                         break;

                         case '06': 
                         totalDaysOfMonth = '30';
                         break;

                         case '07': 
                         totalDaysOfMonth = '31';
                         break;

                         case '08':
                             totalDaysOfMonth = '31';
                             break;
                              
                             case '09': 
                             totalDaysOfMonth = '30';
                             break;

                             case '10':
                                 totalDaysOfMonth = '31';
                                 break;

                                 case '11':
                                     totalDaysOfMonth = '30';
                                     break;
                                     
                                     case '12':
                                         totalDaysOfMonth = '31';
                                         break;

                                         default:
                                             totalDaysOfMonth = '31';
                                             break;
                                     
    }

    let monthEndString = String(currentYear) + '-' + String(updatedPreviousMonthString) + '-' + totalDaysOfMonth;



    let monthStartStringToEpoch = new Date(monthStartString).valueOf();


    let monthEndStringToEpoch = new Date(monthEndString).valueOf();

   
    return {monthStart: monthStartStringToEpoch, monthEnd: monthEndStringToEpoch}
}

export const formatTransactionDate = (date) => {
    let d = ''
    try{
      d = new Date(date * 1000).toISOString().slice(5,10)

    } catch(e){

    }
    return d;
}


export const getCurrentMonthAndYearString = () => {
    let date = new Date();
let month = date.getMonth();
let year = date.getFullYear();


const monthYearString = monthStringsList[month] + ' ' + year;

return monthYearString;
}

export function getDaysBetweenEpochs(priorEpoch, today) {
  // Calculate the difference in milliseconds
  today = today * 1000
  priorEpoch = priorEpoch * 1000

  const timeDifference = Math.abs(today - priorEpoch);

  // Convert milliseconds to days
  const millisecondsInOneDay = 24 * 60 * 60 * 1000;
  const daysDifference = Math.floor(timeDifference / millisecondsInOneDay);

  return daysDifference;
}

export const getMonthAndYearStringFromEpoch = (epoch) => {
    let date = new Date(epoch * 1000);
let month = date.getMonth();
let year = date.getFullYear();


const monthYearString = monthStringsList[month] + ' ' + year;

return monthYearString;
}

export const getMonthBeginningEpoch = () => {
    let date = new Date();
    let month = date.getMonth();
    let year = date.getFullYear();

    // Create a Date object for the first day of the specified month
    const firstDayOfMonth = new Date(year, month, 1);
    
    // Get the epoch timestamp for the first day of the month
    const epochTimestamp = Math.floor(firstDayOfMonth / 1000); 
    return epochTimestamp
}

export const getLastMonthEndingDayEpoch = () => {
    let date = new Date();
    let month = date.getMonth();
    let year = date.getFullYear();
    let day = 1

    switch(month){
        case 0:
            //Current month is January, roll back to 12/31 of previous year
            month = 11
            year =  parseInt(year) - parseInt(1)
            day = 31
            break;

            case 1:
                 //Current month is February, roll back to  January 31st
                month = 0;
                day = 31
                break;

                case 2:
                    //Current month is March, roll back to February 28th
                    month = 1
                    day = 28
                    break;

                    case 3:
                            //Current month is April, roll back to March 31st
                        month = 2
                        day = 31
                        break;

                        case 4:
                             //Current month is May, roll back to April 30th
                            month = 3
                            day = 30
                            break;

                            case 5:
                                //Current month is June, roll back to May 31st
                               month = 4
                               day = 31
                               break;

                               case 6:
                                //Current month is July, roll back to June 30th
                               month = 5
                               day = 30
                               break;

                               case 7:
                                //Current month is August, roll back to July 31st
                               month = 6
                               day = 31
                               break;

                               case 8:
                                //Current month is September,  roll back to August 31st
                               month = 7
                               day = 31
                               break;

                               case 9:
                                //Current month is October,  roll back September 30th
                               month = 8
                               day = 30
                               break;

                               case 10:
                                //Current month is November,  roll back October 31st
                               month = 9
                               day = 31
                               break;

                               case 11:
                                //Current month is December,  roll back November 30th
                               month = 10
                               day = 30
                               break;

                               default:
                                break;
   
   



    }

    

    // Create a Date object for the first day of the specified month
    const lastDayPriorMonth = new Date(year, month, day);
    
    // Get the epoch timestamp for the first day of the month
    const epochTimestamp = Math.floor(lastDayPriorMonth / 1000); 
    return epochTimestamp
}

export const getTodaysEpoch = () => {
    let d = new Date().getTime()
let b = Math.floor(d/1000)
return b;
}

export function getTwoDaysFromToday(){
  let today = new Date();
  today.setDate(today.getDate() + 2);  // Add 2 days to the current date
  let twoDaysFromNow = today.toISOString().slice(5, 10);

  
  return twoDaysFromNow
}


export const getZeroBasedMonthDigitFromEpoch = (epoch) => {
    let date = new Date(epoch* 1000)
    let monthDigit = date.getMonth()
   
    return monthDigit
}

export const getCurrentMonthZeroBasedDigit = () => {
    let date = new Date()
    let monthDigit = date.getMonth()
   
    return monthDigit
}


export const checkIfTwentyFourHoursPassed = (lastEpochScan) => {
    let today= Date.now()
    let lastScan = lastEpochScan * 1000
  
    const todayAsDAte = new Date(today);
  const lastScanAsDate = new Date(lastScan);
  
  // Calculate the time difference in milliseconds
  const timeDifference =  todayAsDAte - lastScanAsDate
  
  // Define the number of milliseconds in 24 hours
  const millisecondsIn24Hours = 12 * 60 * 60 * 1000;
  
  if(timeDifference > millisecondsIn24Hours){

    return true
  }
  else {

    return false
  }
  
  
  }

  export const getCurrentDayDigit = () => {
    const currentDate = new Date();

// Get the current day of the month
const dayOfMonth = currentDate.getDate();
return dayOfMonth
  }

  export const getPriorEpochFromDaysPast = (dayNumber) => {
    
  
     let todayStandardFormat = new Date();
     let thirtyDaysPriorStandardFormat = todayStandardFormat.setDate(todayStandardFormat.getDate() - dayNumber);
     let thirtyDaysEpoch = Math.floor(thirtyDaysPriorStandardFormat /1000);
   
     return thirtyDaysEpoch;
  }


  export const getPriorEpochByNumberOfDays = (days) => {
    let todayStandardFormat = new Date();
    let thirtyDaysPriorStandardFormat = todayStandardFormat.setDate(todayStandardFormat.getDate() - days);
    let thirtyDaysEpoch = Math.floor(thirtyDaysPriorStandardFormat /1000);
    return thirtyDaysEpoch
  }

  export const getNumberOfDaysUntilTheEndOfTheMonth = () => {
    const currentDate = new Date();

// Get the last day of the current month
const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

// Calculate the difference in days
const daysLeft = lastDayOfMonth.getDate() - currentDate.getDate();
return daysLeft
  }

  export function getDaysInMonth() {
    let days = 30
    let currentDate = new Date();
    let currentMonth = currentDate.getMonth()

    switch(currentMonth){
        case 0:
            days = 31
            break;
        case 1:
            days = 28
            break;

            case 2:
                days = 31;
                break;

                case 3:
                    days = 30;
                    break;

                    case 4: 
                        days = 31;
                        break;

                        case 5:
                            days = 30
                            break;

                            case 6:
                                days = 31
                                break;

                                case 7:
                                    days = 31
                                    break;

                                    case 8:
                                        days = 30;
                                        break;

                                        case 9:
                                            days = 31
                                            break;

                                            case 10:
                                                days = 30;
                                                break;

                                                case 11:
                                                    days = 31;
                                                    break;

                                                    default:
                                                        days = 30
                                                        break;
    }
    
    return days;
}



export function generateRandomString(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
}

export function scrubLineItemDescription(description){
  let scrubbed = description.replace(/[;+^$#!@%&*()_?/><]/g," ");
  scrubbed = scrubbed.replace(/\[/g, "").replace(/\]/g, "");

  if(scrubbed === '.' || scrubbed === '..' || scrubbed === '__.*__' || scrubbed === ''){
      return false
     }
     else{
      return scrubbed
     }
}








  




