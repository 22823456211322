import { AltDataContext } from 'contexts/AltDataContext'
import { Backdrop,Grid,Typography,useTheme } from '@mui/material'
import React,{useEffect,useContext,useState} from 'react'
import { getFastFinAccountsHelper,uploadAccountsHelper,refreshFinAccountsHelper } from 'utils/AccountMethods'
import { getTodaysEpoch,getPriorEpochFromDaysPast,getCurrentDayDigit, getCurrentMonthAndYearString,getNumberOfDaysUntilTheEndOfTheMonth,getDaysInMonth } from 'utils/Helpers'
import { handleScanForNewTransactionsHelper,handleRunTrackingRules,uploadNewTransactionsHelper } from 'utils/TransactionMethods'
import { getTrackingRulesHelper,updateSpentValuesHelper,getBudgetPodsByMonth } from 'utils/BudgetMethods'
import { updateUserDocument } from 'utils/UserMethods'
import { calculateTotalsMethod,calculateOverSpentMethod,analyzeTransactionsForRecap } from 'utils/BudgetRecapAnalysisMethods'
import { useNavigate } from 'react-router'
import { getAlreadyTrackedTransactionsForComparison,uploadCategorizedTransactions } from 'utils/TransactionMethods'
import { Ellipsis } from 'react-spinners-css'
import { uploadError } from 'utils/FirebaseAnalyticsMethods'


export default function AutoTransactionScanningUpdated() {


  
    const navigate = useNavigate();
    const {state,dispatch} = useContext(AltDataContext)
    const [mainMessage, setMainMessage] = useState('Running daily scan for transactions...')
    const theme = useTheme();

    useEffect(() => {
      getCurrentMonthTransactions()
    },[])



    async function getCurrentMonthTransactions(){
      //await refreshFinAccountsHelper(state.userData.finCustomerId);
 
      const accounts = await getFastFinAccountsHelper(state.userData.finCustomerId)
      if(accounts.length >= 1 && state.userData.budgetMethod === 'Auto'){
        await uploadAccountsHelper(accounts);
        setMainMessage('Scanning for new transactions...')
        let endingEpoch = getTodaysEpoch()
        let beginningEpoch = getPriorEpochFromDaysPast(30)
   
        let rawTransactionArray = await handleScanForNewTransactionsHelper(state.userData.finCustomerId,beginningEpoch,endingEpoch)
        let trackedTransactions = await getAlreadyTrackedTransactionsForComparison(beginningEpoch)
        filterTransactions(rawTransactionArray,trackedTransactions)

      }
      else {
        handleFinishAndGoToMainSemiAuto();
      }
  
    }

    function filterTransactions(rawTransactionArray,trackedTransactions){
      let filteredTransactions = []
      for(let i = 0; i < rawTransactionArray.length; i++){
          let transaction = rawTransactionArray[i]
          let existingIndex = trackedTransactions.findIndex(obj => parseInt(obj.id) === parseInt(transaction.id))
          if(existingIndex === -1){
              filteredTransactions.push(transaction)
          }
      }
      if(filteredTransactions.length >= 1){
          getTrackingRulesAndScan(filteredTransactions)
      }
      else{
          handleFinishAndGoToMainAuto();
      }
  
    }

    async function getTrackingRulesAndScan(rawTransactionArray){
      let trackingRules = await getTrackingRulesHelper();
      setMainMessage('Running your tracking rules...')
      const {submitTransactionsArray,uncategorizedArray}= handleRunTrackingRules(trackingRules,rawTransactionArray)
      
      let groupArray = []
      for(let i = 0; i < submitTransactionsArray.length; i++){
          if(i === 0){
              
              groupArray.push({transactionMonthAndYear:submitTransactionsArray[i].transactionMonthAndYear,podName:submitTransactionsArray[i].podName,
                  lineItem:submitTransactionsArray[i].lineItem,amount:submitTransactionsArray[i].transaction.amount
              })
              
          }
          else {
              let checkIndex = groupArray.findIndex(obj => obj.podName === submitTransactionsArray[i].podName && obj.lineItem === submitTransactionsArray[i].lineItem && obj.transactionMonthAndYear === submitTransactionsArray[i].transactionMonthAndYear)
              if(checkIndex === -1){

                  groupArray.push({transactionMonthAndYear:submitTransactionsArray[i].transactionMonthAndYear,podName:submitTransactionsArray[i].podName,
                      lineItem:submitTransactionsArray[i].lineItem,amount:submitTransactionsArray[i].podName === 'Savings' ? Math.abs(submitTransactionsArray[i].transaction.amount) : parseFloat(submitTransactionsArray[i].transaction.amount)
                  })
              }else {
                  if(submitTransactionsArray[i].podName === 'Savings'){

                      groupArray[checkIndex].amount = parseFloat(groupArray[checkIndex].amount) + Math.abs(submitTransactionsArray[i].transaction.amount)
                  }
                  else {
                      if(submitTransactionsArray[i].podName !== 'Delete'){
                          groupArray[checkIndex].amount = parseFloat(groupArray[checkIndex].amount) + parseFloat(submitTransactionsArray[i].transaction.amount)
                      }
                  }
                 
              }
          }
      }

 
      setMainMessage('Running tracking rules...')
      await uploadCategorizedTransactions(submitTransactionsArray);
      await updateSpentValuesHelper(groupArray)
      setMainMessage('Updating your budget...')

      await uploadNewTransactionsHelper(uncategorizedArray)

      handleFinishAndGoToMainAuto();

    }

    async function handleFinishAndGoToMainSemiAuto(){
      let todaysEpoch = getTodaysEpoch();
      let todaysDigit = getCurrentDayDigit();
      await updateUserDocument({lastTransactionScanEpoch: todaysEpoch,lastScanDigit: todaysDigit})
      let month = getCurrentMonthAndYearString();
      let podsArray = await getBudgetPodsByMonth(month)

      dispatch({type:'UPDATE_PODS',payload:podsArray})
      dispatch({type:'UPDATE_BUDGET_MONTH',payload:month})
      navigate('/dashboard/budgethome',{state:{needsToRefresh: false}})
    }

    async function handleFinishAndGoToMainAuto(){
      let todaysEpoch = getTodaysEpoch();
      let todaysDigit = getCurrentDayDigit();
      await updateUserDocument({lastTransactionScanEpoch: todaysEpoch,lastScanDigit: todaysDigit})
      let month = getCurrentMonthAndYearString();
      let podsArray = await getBudgetPodsByMonth(month)
      dispatch({type:'UPDATE_PODS',payload:podsArray})
      dispatch({type:'UPDATE_BUDGET_MONTH',payload:month})
      handleFinishBudgetRecap(podsArray)
    }

    async function handleFinishBudgetRecap(podsArray){
      try{
        let monthlyGoal = state.monthlySavingsGoal
        const { plannedExpenses,actualExpenses,} =  calculateTotalsMethod(podsArray,monthlyGoal)
  
        const {overSpentCount} = calculateOverSpentMethod(podsArray)

        let percentage = (parseFloat(actualExpenses)/parseFloat(plannedExpenses)) * 100
        percentage = percentage.toFixed(2)
   
        let sentenceOne = `You have spent ${new Intl.NumberFormat('us-US',{style:'currency',currency:'USD',maximumFractionDigits:2}).format(actualExpenses)} so far this month which is ${percentage}% of your budgeted amount.`
        let sentenceTwo = 'nil'
        let sentenceThree = 'nil'
        let sentenceFour = 'nil'
   
        if(actualExpenses > 0){
     
           let daysLeft = getNumberOfDaysUntilTheEndOfTheMonth()
           if(daysLeft === 0){
            daysLeft = 1
           }
           let currentDate = new Date();
           let daysTranspired = currentDate.getDate();
           let lengthOfMonth = getDaysInMonth();
           let paceRate = lengthOfMonth/daysTranspired
   
           let dollarPace = parseFloat(paceRate) * parseFloat(actualExpenses)
           //dollarPace = dollarPace.toFixed(2)
   
           let pacePercentage = parseFloat(dollarPace)/parseFloat(plannedExpenses) * 100
           pacePercentage = pacePercentage.toFixed(2)
           sentenceTwo = `You are on pace to spend ${new Intl.NumberFormat('us-US',{style:'currency',currency:'USD',maximumFractionDigits:2}).format(dollarPace)} which is ${pacePercentage}% of your budgeted amount.`
           
        }
        else {
           return navigate('/dashboard/budgethome',{state:{needsToRefresh: false}})
        }
   
        if(overSpentCount > 0){
            sentenceFour = `You have overspent in ${overSpentCount} line items.  Reallocate funds from other line items with excess amounts to correct.`
        }
       
        const {description,amount} = await analyzeTransactionsForRecap();
        if(description !== 'nil' && typeof amount !== 'nil'){
            sentenceThree = `You have spent ${new Intl.NumberFormat('us-US',{style:'currency',currency:'USD',maximumFractionDigits:2}).format(Math.abs(amount))} at ${description} so far this month, the most of any payee.`
        }
        navigate('/budgetrecapafterscan',{state:{sentenceOne:sentenceOne,sentenceTwo:sentenceTwo,sentenceThree: sentenceThree,sentenceFour: sentenceFour}})
      }catch(e){
        uploadError(e.message,'AutoTransactionScanningUpdated, handleFinishBudgetRecap')
      }

     
    }

  return (
    <Backdrop sx={{backgroundColor:'white'}} open={true} style={{zIndex:1400}}>
    <Grid container direction='column'  justifyContent='center' alignItems='center'>
      <Grid item>
      <Ellipsis color={theme.palette.primary.main} />
      </Grid>
     <Grid item style={{marginTop:-15}}>
        <Typography style={{color: theme.palette.primary.fiverGrayText, fontWeight:'bold'}}>{mainMessage}</Typography>
      </Grid> 

    </Grid>
    </Backdrop> 
        )
  
}
