

import { getAuth } from "firebase/auth";
import { getFirestore,collection,getDocs,query, where } from "firebase/firestore";
import React, { useState, useEffect,} from 'react'
import { Button,useTheme,useMediaQuery,Typography,Table,TableHead,TableBody,TableRow,TableCell } from "@mui/material";
import { useLocation,useNavigate } from "react-router";
import LightTextField from "uicomponents/LightTextField";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { uploadError } from "utils/FirebaseAnalyticsMethods";


const db = getFirestore();

export default function UpdateManageRules(){

    const theme = useTheme();
    const isDownMedium = useMediaQuery(theme.breakpoints.down('md'));
    const location = useLocation();
    const navigate = useNavigate();
    const [displayClearSearch, setDisplayClearSearch] = useState(false)
    const [payeeSearchValue, setPayeeSearchValue] = useState('')
    const [rulesArray, setRulesArray] = useState([])    
   
    

    useEffect(() => {
      document.body.style.backgroundColor = 'white'
    },[])
 
    useEffect(() => {

      getTrackingRules();   
      },[])


    async function getTrackingRules(){
      try{
        let uid = getAuth().currentUser.uid
        if(location.state.queryWord === 'All'){
            let trackingRef = collection(db,'EuklesUsersData',uid,'TrackingRules')

          let rules = await getDocs(trackingRef).then((querySnapshot) => {
    
    
            let localArray = []
            querySnapshot.forEach((doc) => {
              let myObject = {docId: doc.id, andLineItem: doc.data().andLineItem, thenTrackToPod: doc.data().thenTrackToPod,
                 transactionBestRep: doc.data().transactionBestRep, transactionCategory: doc.data().transactionCategory,
              transactionPayeeName: doc.data().transactionPayeeName}
            
              localArray.push(myObject)
            })
            return localArray
          
         
        }).catch(e => {
          return []
        })
      
        rules.sort((a, b) => a.transactionCategory.localeCompare(b.transactionCategory));

        setRulesArray(previousState => [...rules])
        }
        else {
            let trackingCollection = collection(db,'EuklesUsersData',uid,'TrackingRules')
            let q  = query(trackingCollection,where("andLineItem","==",location.state.queryWord))
          let rules = await getDocs(q).then((querySnapshot) => {
    
    
            let localArray = []
            querySnapshot.forEach((doc) => {
              let myObject = {docId: doc.id, andLineItem: doc.data().andLineItem, thenTrackToPod: doc.data().thenTrackToPod,
                 transactionBestRep: doc.data().transactionBestRep, transactionCategory: doc.data().transactionCategory,
              transactionPayeeName: doc.data().transactionPayeeName}
            
              localArray.push(myObject)
            })
            return localArray
          
         
        }).catch(e => {
          return []
        })
      
        rules.sort((a, b) => a.transactionCategory.localeCompare(b.transactionCategory));

        setRulesArray(previousState => [...rules])
        }
      }catch(e){
        uploadError(e.message,'UpdatedManageRules.js, getTrackingRules')
      }
   
      
      }
  
    function handleRowTap(position){

        try{
          let rule = rulesArray[position]
          navigate('/dashboard/editrule',{state:{selectedRule: rule}})
        }catch(e){
          uploadError(e.message,'updatedManageRules, handleRowTap')
        }
        

        
    
    }


    function searchForPayee(){
      try{
        let currentArray = rulesArray;
        currentArray = currentArray.filter(obj => obj.transactionPayeeName.includes(payeeSearchValue))
        setRulesArray(previousState => [...currentArray])
        setDisplayClearSearch(true)
      }catch(e){
        uploadError(e.message,'updatedmanagerules, searchforpayee')
      }
     
    }

    function clearResults(){
        getTrackingRules();
        setPayeeSearchValue('')
        setDisplayClearSearch(false)
    }



    return(
        <div style={{backgroundColor:'white', display:'flex',flexDirection:'column'}}>
          

        <Typography style={{padding:10, textAlign:'center',marginTop:25,color:'black', fontWeight:'bold'}}>Tap on a rule to edit </Typography>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>

<div style={{display:'flex',flexDirection:'row',justifyContent:'ceter', alignSelf:'center', alignContent:'center'}}>

<LightTextField fullWidth style={{width:400,alignSelf:'center',marginBottom:25}} name="orderNoa"
                        InputProps={{disableUnderline:true,startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>}}
                        placeholder='Search for a payee'
                        inputProps={{style: {fontWeight:'bold'}}}
                        onChange={(e) => setPayeeSearchValue(e.target.value)}
                        value={payeeSearchValue}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                              searchForPayee();
                            }
                          }}
                        onBlur={() => searchForPayee()}
                          
                            /> 
                            </div>

{displayClearSearch ? <Button variant='text' style={{color:'red'}} onClick={() => clearResults()} mode='text'>X Clear</Button>  : null}

        </div>

   
        <Table style={{width: isDownMedium ? '100%' : 'auto',  minWidth: isDownMedium ? 300 : 500,}}>
            <TableHead sx={{backgroundColor: theme.palette.primary.dialogBackground}}>
              <TableRow>
              <TableCell  style={{color:'white', fontWeight:'bold'}}>Payee</TableCell>
              <TableCell style={{color:'white', cursor:'pointer', fontWeight:'bold'}}>Group</TableCell>
              <TableCell style={{color:'white', cursor:'pointer', fontWeight:'bold'}}>Line Item</TableCell>
              </TableRow>
        
            </TableHead>
            <TableBody style={{backgroundColor:'white', }}>

  
            {rulesArray.map((item,i) => {
                            return(
                                <TableRow style={{cursor:'pointer'}} onClick={() => handleRowTap(i,item)} key={i.toString()}>
                                    <TableCell  >
                                    <Typography style={{color: theme.palette.primary.blackcoral, fontWeight:'600'}}>{item.transactionPayeeName}: {item.transactionBestRep}</Typography>
                                    </TableCell>
                                    <TableCell  >
                                    <Typography style={{color: theme.palette.primary.blackcoral, fontWeight:'600'}}>{item.thenTrackToPod}</Typography>
                                    </TableCell>
                                    <TableCell  >
                                    <Typography style={{color: theme.palette.primary.blackcoral, fontWeight:'600'}}>{item.andLineItem}</Typography>
                                    </TableCell>
                                 
                                 
                                </TableRow>
                            )
                        })}
            </TableBody>
            </Table>
    







        </div>
    )
}