import {  Button, Card, useMediaQuery, Grid,  Typography,} from "@mui/material";
import FlexBox from "uicomponents/FlexBox.jsx";
import Budget from '../mainpages/graphics/budgeteven.svg'



const DeleteAccount = () => {

  const downMedium = useMediaQuery(theme => theme.breakpoints.down('md'))  


  return <FlexBox sx={{alignItems: "center",flexDirection: "column",justifyContent: "center",height: {sm: "100%"}}}>
      <Card sx={{padding: downMedium ? 2 : 4,marginTop: downMedium ? 5 : 10,maxWidth: downMedium ? 375 : 600,boxShadow: 1}}>
        <FlexBox alignItems="center" flexDirection="column" justifyContent="center">
          <img src={Budget} width={downMedium ? 300 : 400} height={downMedium ? 150 : 200} alt='BudgetEven' />
        <Typography style={{textAlign:'center'}}>To delete your account, please tap the button below to email us your request.</Typography>
        </FlexBox>

        <Grid container direction='row' justifyContent='center'>
          <Grid item>
          <Button onClick={() => window.open('mailto:help@budgeteven.com.com?subject=DeleteAccount')} variant='link'>Delete My Acccount</Button>
          </Grid>
        </Grid>

      </Card>
    </FlexBox>
};

export default DeleteAccount;