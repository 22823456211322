
import { AltDataContext } from 'contexts/AltDataContext';
import BackDropLoading from 'utils/BackDropLoading';
import BottomSpacer from 'uicomponents/BottomSpacer';
import { formatTransactionDate,getTodaysEpoch,getPriorEpochByNumberOfDays } from 'utils/Helpers';
import { getFinTransactionsSingleAccount } from 'utils/TransactionMethods';
import React,{useEffect, useState,useContext} from 'react'
import { Table,TableHead,TableRow,TableCell,TableBody,useTheme,Typography,useMediaQuery,Box } from '@mui/material'
import {useLocation } from 'react-router';


export default function UpdatedViewAccountTransactions() {

    const theme = useTheme();
    const {state} = useContext(AltDataContext)
    const location = useLocation();
    const isDownMedium = useMediaQuery(theme.breakpoints.down('md'));
    
    const [showLoading,setShowLoading] = useState(true)
    const [transactionArray,setTransactionArray] = useState([])

 
    useEffect(() => {
        getLastMonthsTransactions();
    },[])


     
      async function getLastMonthsTransactions(){
        let accountId = location.state.accountId

        let todayEpoch = getTodaysEpoch();
        let startDate = getPriorEpochByNumberOfDays(30)      
        todayEpoch = todayEpoch * 1000
        startDate = startDate * 1000
  
        
        let newTransactionArray = await getFinTransactionsSingleAccount(state.userData.finCustomerId,accountId,startDate,todayEpoch)

   
          if(newTransactionArray !== false){
            try{
              newTransactionArray[0].sort((a,b) => b.transactionDate - a.transactionDate)
            }catch(e){
              console.log(e)
            }
            setTransactionArray(previousState => [...newTransactionArray[0]])
          }
      
       setShowLoading(false)
    
    
    }
  
   
      

    return (
            
        <Box pt={2} pb={10} style={{marginLeft: 60}}>

        <div style={{padding:10,display:'flex',flexDirection:'row', justifyContent:'center',alignItems:'center',alignContent:'center'}}>
        <img src={location.state.icon} style={{height:100,width:'auto'}}></img>
            </div>
        
           <div style={{alignSelf:'center', display:'flex',flexDirection:'row',justifyContent:'center'}}>
          <Table style={{alignSelf:'center',width: isDownMedium ? '100%' : 'auto',   minWidth: isDownMedium ? 300 : 900, marginTop:25}}>
                <TableHead sx={{backgroundColor: theme.palette.primary.dialogBackground}}>
                  <TableRow>
                      <TableCell  style={{color:'white', fontWeight:'bold'}}>Date</TableCell>
                      <TableCell style={{color:'white', cursor:'pointer', fontWeight:'bold'}}>Description</TableCell>
                      <TableCell style={{color:'white', cursor:'pointer', fontWeight:'bold'}}>Amount</TableCell>
                  </TableRow>
                </TableHead>

        <TableBody style={{backgroundColor:'white'}}>
        {transactionArray.map((item,i) => {
                        return(
                            <TableRow key={i.toString()}>

                                <TableCell  >
                                <Typography style={{color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14, fontWeight:'600'}}>{formatTransactionDate(item.transactionDate)}</Typography>
                                </TableCell>

                                <TableCell>
                                <Typography style={{color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14, fontWeight:'600'}} >{item.categorization.normalizedPayeeName}</Typography>
                                </TableCell>
                             
                                <TableCell>
                                      <Typography style={{textAlign:'center',color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14, fontWeight:'600'}} >{new Intl.NumberFormat('us-US',{style:'currency',currency:'usd'}).format(item.amount)}</Typography>
                                </TableCell>
                            </TableRow>
                        )
                    })}
        </TableBody>
        </Table>
        </div>
        
        <BottomSpacer />
        {showLoading ?  <BackDropLoading loadingMessage='Retrieving Transactions...' /> : null}
       </Box>
      )
}
