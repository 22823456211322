import React, {createContext,useReducer} from 'react';

const initialState = {
  newLineItem:{dayDigit:1,description:'',docId:'',one:{amount:0.00},two:{amount:0.00},three:{amount:0.00},
              four:{amount:0.00},five:{amount:0.00},six:{amount:0.00},seven:{amount:0.00},eight:{amount:0.00},
              nine:{amount:0.00},ten:{amount:0.00},eleven:{amount:0.00},twelve:{amount:0.00}},
  entriesArray:[],
  masterLineItemArray:[],
  startingBalance:[],
}

function dataReducer(state,action){

    switch(action.type) {
        case 'UPDATE_ENTRIES_ARRAY':
            return {...state,entriesArray:action.payload}
        
            case 'UPDATE_MASTER_LINE_ITEM_ARRAY':
                return {...state,masterLineItemArray:action.payload}

                case 'UPDATE_STARTING_BALANCE':
                    return {...state,startingBalance:action.payload}
                    case 'UPDATE_NEW_LINE_ITEM':
                        return {...state,newLineItem:action.payload}
            

        default:
            return state;
    }
}

export const CashFlowContext = createContext();

export const CashFlowProvider = ({children}) => {
    const [state,dispatch] = useReducer(dataReducer,initialState)

    return (
        <CashFlowContext.Provider value={{state,dispatch}}>
            {children}
        </CashFlowContext.Provider>
    )
}