import { Box, ButtonBase,styled, } from "@mui/material";
import { emailCustomerSupport } from "utils/Helpers";
import FlexBox from "uicomponents/FlexBox";
import { Fragment, useRef, useState} from "react";
import { getAuth } from "firebase/auth";
import MenuIcon from '@mui/icons-material/Menu';
import MenuPopover from "./MenuPopover";
import { Small } from "uicomponents/Typography";


const StyledSmall = styled(Small)(({theme}) => ({
  display: "block",
  padding: "5px 1rem",
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.mode === "light" ? theme.palette.secondary.light : theme.palette.divider
  }
}));

const OnBoardingMenu = ({navigate}) => {

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);


  function handleHelpClick(){
    setOpen(false);
    emailCustomerSupport();

  }

  async function handleSignOut(){
    await getAuth().signOut();
    navigate('/')
    setOpen(false);
  }



  return <Fragment>
      <ButtonBase disableRipple ref={anchorRef} onClick={() => setOpen(true)}>
      <MenuIcon sx={{width:35, height:35}} />
      </ButtonBase>

      <MenuPopover hiddenViewButton maxWidth={230} minWidth={200} popoverOpen={open} anchorRef={anchorRef} popoverClose={() => setOpen(false)} 
      title={<FlexBox alignItems="center"></FlexBox>}>
        <Box pt={1}>
          <StyledSmall style={{fontSize:16}} onClick={() => handleHelpClick()}>
            Help
          </StyledSmall>
          <StyledSmall style={{fontSize:16}} onClick={() => handleSignOut()} >
           Log Out
          </StyledSmall>
        </Box>
      </MenuPopover>
    </Fragment>
};

export default OnBoardingMenu;