import React from "react";
import { Navigate } from "react-router";
import { useAuth } from "contexts/AuthenticationContext";


const ProtectedRoute = ({ children }) => {
    const { isAuthenticated } = useAuth();
  
    return isAuthenticated ? children : <Navigate to="/" />;
  };
  
  export default ProtectedRoute;