import React,{createContext,useReducer} from 'react';

const initialState = {
    assetsArray:[],
    liabilitiesArray:[],
    assetTotal: 0.00,
    liabilityTotal: 0.00,
    netWorthTotal: 0.00
}

function dataReducer(state,action){
    switch(action.type){
        case 'UPDATE_ASSETS':
            return {...state,assetsArray:action.payload}

            case 'UPDATE_LIABILITIES':
                return {...state,liabilitiesArray:action.payload}

                case 'UPDATE_ASSET_TOTAL':
                    return {...state,assetTotal:action.payload}

                    case 'UPDATE_LIABILITY_TOTAL':
                        return {...state, liabilityTotal: action.payload}

                        case 'UPDATE_NETWORTH_TOTAL':
                            return {...state, netWorthTotal: action.payload}

                            default:
                                return null
    }
}

export const NetWorthContext = createContext();

export const NetWorthProvider = ({children}) => {
    const [state,dispatch] = useReducer(dataReducer,initialState)

    return (
        <NetWorthContext.Provider value={{state,dispatch}}>
            {children}
        </NetWorthContext.Provider>
    )
}