import AppTextField from "uicomponents/AppTextField";
import Axios from 'axios';
import BottomSpacer from "uicomponents/BottomSpacer";
import { Box,Grid,Divider,Typography,useTheme } from "@mui/material";
import { BudgetContextTwo } from "contexts/BudgetContextTwo";
import {CardElement, useStripe, useElements,} from '@stripe/react-stripe-js';
import FlexBetween from "uicomponents/FlexBetween";
import { generateRandomString } from "utils/Helpers";
import { getFunctions, httpsCallable } from "firebase/functions";
import { H3} from "uicomponents/Typography";
import LoadingButton from '@mui/lab/LoadingButton';
import OnBoardingMenu from "../MenuPopover/OnBoardingMenu";
import React,{useState, useContext, useEffect} from 'react'
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { uploadError } from "utils/FirebaseAnalyticsMethods";
import { testMode } from "utils/GlobalConstans";
import { handleSetUpNewUser, updateUserDocument } from "utils/UserMethods";
import BackDropLoading from "utils/BackDropLoading";
import LogoHeader from "./LogoHeader";


const functions = getFunctions();



const OnBoardCheckout = () => {

const{userUid, userEmail} = useContext(BudgetContextTwo)
const theme = useTheme();
const elements= useElements();
const location = useLocation();
const navigate = useNavigate();
const stripe = useStripe();

const [canSubmit,setCanSubmit] = useState(false)
const [firstName,setFirstName] = useState('')
const [firstNameError,setFirstNameError] = useState(false)
const [loading, setLoading] = useState(false)
const [stripeErrorMessage,setStripeErrorMessage] = useState('')
const [stripeLoading,setStripeLoading] = useState(false)
const [zip,setZip] = useState('')
const [zipError,setZipError] = useState(false)


useEffect(() => {
  document.body.style.backgroundColor = 'white'
},[])




useEffect(() => {
  if(zip !== '' && firstName !== '' && stripe && elements){
    setCanSubmit(true)
  }
},[zip,firstName])



function checkFields(){
  if(firstName === ''){
    return setFirstNameError(true)
  }

  if(zip === ''){
    return setZipError(true)
  }

  else {
    createStripePaymentMethod()
  }


}

  async function createFinicityCustomerAuto(){
 
    try{
      let url = testMode ? 'createFinCustomerTest' : 'createFinCustomer'
      let createFinMethod = httpsCallable(functions,url);
      let userName = generateRandomString(10)
      let customerId = await createFinMethod({username: userName}).then((res) => {
          return res.data.customerId;
      }).catch((err) => {
          
          return false;
      });
      
      if(customerId !== false){
        await handleSetUpNewUser('Auto',customerId,true);
        navigate("/mainloading",{state:{needsToRefresh:false}});      
      }
      else {
        setLoading(false);
        toast.error('There was an error processing your request.')
      }
      
    }catch(e){

    }
 
    } 


async function createStripePaymentMethod () {

  try{
    setStripeLoading(true)
    setStripeErrorMessage('')
     
      if(!stripe || !elements){
        return;
      }
      const cardElement = elements.getElement(CardElement);
      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type:'card',
        card: cardElement,
      });
 
      if(error){
       
       setLoading(false);
      setStripeLoading(false);
       toast.error(`There was an error processing your request. ${error.message}  `)
    
      }
      else {
      
        const paymentMethodId = paymentMethod.id;
        
        const data = {paymentId: paymentMethodId, email: userEmail, uid: userUid, name:firstName, zip: zip}
       createStripeCustomer(data);
    
      }
  }catch(e){
    uploadError(e.message,'OnBoardCheckout.js, createStripePaymentMethod')
  }

   }

   async function createStripeCustomer(data){

    try {
      let url = testMode ? 'https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeCustomerTest' : 'https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeCustomer'
       let createCustomerResult = await Axios.post(url, data).then(res => {
               return res.data;
          }).catch(error => {
            
               return error;
               })

               if(!createCustomerResult.encounteredError){
                  createStripeSubscriptionAutoMethod(createCustomerResult.customerId)
               }
               else if(createCustomerResult.encounteredError){
                setLoading(false);
                setStripeLoading(false);
                toast.error('There was an error processing your request.')
                setStripeErrorMessage(createCustomerResult.errorMessage)
          
               }
  
              
    }catch(e){
      uploadError(e.message,'OnBoardCheckout.js createStripeCustomer')
    }
  
           
    }

 
    async function createStripeSubscriptionAutoMethod(customerId){
    
      try{
        let url = testMode ? 'https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeSubscriptionTest' : 'https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeSubscription'
        setStripeLoading(false);
        setLoading(true)
       const customerData = {customerId: customerId, uid: userUid}
             let stripeSubscription = await Axios.post(url, customerData ).then(res => {
   
              return res.data;
              }).catch(error => {
           
                return error;
              })
            
   
              if(stripeSubscription.encounteredError){
             
                setLoading(false);
                setStripeLoading(false);
                setStripeErrorMessage(stripeSubscription.errorMessage)
                toast.error(`There was an error processing your request. ${stripeSubscription.errorMessage}`)
              }
              else if(!stripeSubscription.encounteredError) {
                await updateUserDocument({stripeSubscriptionId:stripeSubscription.subscriptionId})
                createFinicityCustomerAuto();

              }
      }catch(e){
        uploadError(e.message,'OnBoardCheckout.js,createStripeSubscriptionAutoMethod')
      }

 
      
 }



  if(loading){
    return (
        <BackDropLoading loadingMessage='Please wait...' />
    )
  }
  else {
  return (
    <Box pt={2} pb={4} >
     
        <div style={{position:'absolute',top:15,right:25}}>
            <OnBoardingMenu navigate={navigate} />
          </div>
        
          <LogoHeader />
   

        <Grid container style={{justifyContent:'center',marginTop:25,alignSelf:'center'}}>
          <div style={{width:550, height:750,backgroundColor:'white',borderRadius:5,border: '1px solid grey'}}>  
              <div style={{width:550,height:100,alignContent:'center',backgroundColor: theme.palette.primary.main,marginBottom:15}}>

                    <Typography variant='h5' style={{textAlign:'center',color:'white',fontWeight:'bold'}}>{location.state.planType === 'Auto' ? 'Budget Even: Auto' : 'Budget Even: Semi-Auto'}</Typography>
              </div>

          
              <Typography style={{color:'red',fontStyle:'italic'}}>{stripeErrorMessage}</Typography>
              <div style={{marginLeft:10,marginRight:10,marginTop:25}}>
              <Typography style={{fontWeight:500}}>Card information</Typography>
              <CardElement />
              </div>

              <div style={{margin:10}}>
                  <Typography style={{fontWeight:500}}>Name on card</Typography>
                  <AppTextField  onChange={(e) => setFirstName(e.target.value)}fullWidth error={firstNameError} helperText='' />
               </div>

               <div style={{margin:10}}>
                  <Typography style={{fontWeight:500}}>Zip</Typography>
                  <AppTextField  onChange={(e) => setZip(e.target.value)}error={zipError}  />
               </div>

               <Divider style={{marginTop:25}} />
               <Typography style={{marginLeft:10,marginRight:10,fontWeight:500,marginTop:25}}>Order Summary</Typography>
                <Typography style={{marginLeft:10,marginRight:10,marginBottom:10}}>By clicking the subscribe button below, you agree to subscribe to the Budget Even Auto mode for a monthly payment of $4.99.
                You may cancel your subscription at any time.</Typography>

          <Box paddingX={3}>

            <FlexBetween my={2}>
              <H3>Today's Total</H3>
              <H3 color="primary.main">$4.99</H3>
            </FlexBetween>

            <LoadingButton disabled={canSubmit ? false : true} fullWidth  onClick={() => checkFields()}  loading={stripeLoading}  variant="contained">
                Subscribe
              </LoadingButton>

          
          </Box>

          </div>    
        </Grid>
    <BottomSpacer />
    </Box>
  )
  
}
 
}

export default OnBoardCheckout;
