import { formatTransactionDate } from 'utils/Helpers';
import React from 'react'
import { TableCell,TableRow,Typography } from '@mui/material';

export default function AccountLineItem({item,handleFixInstitution,handleDeleteAccountClick,navigate,theme,position}) {


    function camelCaseForce(title){
      
        let titleArray = title.split(" ")
        for(let i = 0; i < titleArray.length; i++){
          
          titleArray[i] = titleArray[i].toLowerCase()
          titleArray[i] = titleArray[i][0].toUpperCase() + titleArray[i].substring(1)
        }
      
        titleArray = titleArray.join(" ")
        if(titleArray === 'Creditcard'){
          titleArray = 'Credit Card'
        }
        return titleArray
       
      }


    function determineIcon(){
        if(item.logo === '' || item.logo === 'nil' || typeof item.logo ==='undefined'){
            return (
                <TableCell></TableCell>
            )
        }
        else {
            return (
                <img src={item.logo} height={35} style={{marginTop:20}}></img>
            )
        }
    }


  return (
    <TableRow >
            {determineIcon()}
            <TableCell>
            <Typography  style={{color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14,maxWidth:200, fontWeight:'600'}}>{camelCaseForce(item.accountNickname)}</Typography>
            </TableCell>

          <TableCell >
              <Typography style={{textAlign:'center',color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14, fontWeight:'600'}} >{new Intl.NumberFormat('us-US',{style:'currency',currency:'usd'}).format(item.balance)}</Typography>
          </TableCell>

          <TableCell >
              <Typography style={{textAlign:'center',color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14, fontWeight:'600'}} >{formatTransactionDate(item.aggregationSuccessDate)}</Typography>
          </TableCell>

          <TableCell >
              <Typography style={{textAlign:'center',color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14, fontWeight:'600'}} >{item.detailPaymentDueDate === '' ? 'n/a' : formatTransactionDate(item.detailPaymentDueDate)}</Typography>
          </TableCell>

          <TableCell >
          <Typography style={{textAlign:'center',color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14, fontWeight:'600'}} >{item.detailLastPaymentAmount === '' ? 'n/a' :new Intl.NumberFormat('us-US',{style:'currency',currency:'usd'}).format(item.detailLastPaymentAmount)} </Typography>
          </TableCell>

          <TableCell >
          <Typography onClick={() => navigate('/dashboard/accounttransactions',{state:{accountId: item.docId,icon:item.logo}})} style={{textAlign:'center',cursor:'pointer',color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14, fontWeight:'600'}}>View Transactions</Typography>
          </TableCell>

          <TableCell >
          <Typography onClick={() => handleFixInstitution(item)} style={{textAlign:'center',cursor:'pointer',color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14, fontWeight:'600'}} >Update Credentials</Typography>
          </TableCell>

          <TableCell >
          <Typography onClick={() => handleDeleteAccountClick(item,position)} style={{textAlign:'center',cursor:'pointer',color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14, fontWeight:'600'}} >Remove Account</Typography>
          </TableCell>
</TableRow>
  )
}
