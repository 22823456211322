import { AltDataContext } from "contexts/AltDataContext";
import AppTextField from "uicomponents/AppTextField";
import Axios from 'axios';
import BackDropLoading from "utils/BackDropLoading";
import { Box, Grid, Divider,useMediaQuery,Typography,useTheme } from "@mui/material";
import BottomSpacer from "uicomponents/BottomSpacer";
import {CardElement, useStripe, useElements,} from '@stripe/react-stripe-js';
import FlexBetween from "uicomponents/FlexBetween";
import { generateRandomString} from "utils/Helpers";
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { H3} from "uicomponents/Typography";
import { LoadingButton } from "@mui/lab";
import LogoHeader from "pages/onboarding/PricingAndCheckOut/LogoHeader";
import React,{useState, useContext,useEffect} from 'react'
import { testMode } from "utils/GlobalConstans";
import toast from "react-hot-toast";
import { updateUserDocument } from "utils/UserMethods";
import { uploadError } from "utils/FirebaseAnalyticsMethods";
import {  useNavigate, useLocation } from "react-router-dom";



const functions = getFunctions();

const UpdatedCheckOutInterior = () => {


const theme = useTheme();
const {state,dispatch} = useContext(AltDataContext)

const isDownLarge = useMediaQuery(theme.breakpoints.down('lg'));
const elements= useElements();
const navigate = useNavigate();
const stripe = useStripe();


const [canSubmit,setCanSubmit] = useState(false)
const [firstName,setFirstName] = useState('')
const [firstNameError,setFirstNameError] = useState(false)
const [zip,setZip] = useState('')
const [zipError,setZipError] = useState(false)
const [loading, setLoading] = useState(false)
const [loadingMessage,setLoadingMessage] = useState('Please wait...')
const [stripeErrorMessage,setStripeErrorMessage] = useState('')
const [stripeLoading,setStripeLoading] = useState(false)


useEffect(() => {
  if(zip !== '' && firstName !== '' && stripe && elements){
    setCanSubmit(true)
  }
},[zip,firstName])



function checkFields(){
  if(firstName === ''){
    return setFirstNameError(true)
  }


  if(zip === ''){
    return setZipError(true)
  }

  else {
    createStripePaymentMethod()
  }



}

async function createStripePaymentMethod () {

  try{
    setStripeLoading(true)
    setStripeErrorMessage('')
     
      if(!stripe || !elements){
        return;
      }
      const cardElement = elements.getElement(CardElement);
      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type:'card',
        card: cardElement,
      });
 
      if(error){
       
       setLoading(false);
      setStripeLoading(false);
       toast.error(`There was an error processing your request. ${error.message}  `)
    
      }
      else {
      
        const paymentMethodId = paymentMethod.id;
        let userEmail = getAuth().currentUser.email
        let userUid = getAuth().currentUser.uid
        const data = {paymentId: paymentMethodId, email: userEmail, uid: userUid, name:firstName, zip: zip}
       createStripeCustomer(data);

      }
  }catch(e){
    uploadError(e.message,'OnBoardCheckout.js, createStripePaymentMethod')
  }

   }

   async function createStripeCustomer(data){

    try{

    
      let url = testMode ? 'https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeCustomerTest' : 'https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeCustomer'
  
       let createCustomerResult = await Axios.post(url, data).then(res => {
        return res.data;
                 
      }).catch(error => {
     
        return error;
        })
    
               if(!createCustomerResult.encounteredError){
                createStripeSubscriptionAutoMethod(createCustomerResult.customerId)
             
               }
               else if(createCustomerResult.encounteredError){
                setLoading(false);
                setStripeLoading(false);
                toast.error('There was an error processing your request.')
                setStripeErrorMessage(createCustomerResult.errorMessage)
               }
  
      
    }catch(e){
      uploadError(e.message,'UpdatedCheckOutInterior, createStripeCustomer.js')
    }
  
           
    }

 
    async function createStripeSubscriptionAutoMethod(customerId){
      //https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeSubscription
      //full auto
      let url = testMode ? 'https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeSubscriptionTest' : 'https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeSubscription'
      setStripeLoading(false);
      setLoading(true)
      let userUid = getAuth().currentUser.uid
     const customerData = {customerId: customerId, uid: userUid}
           let stripeSubscription = await Axios.post(url, customerData ).then(res => {
 
            return res.data;
            }).catch(error => {
             console.log('error creating subscription')
              return error;
            })
 
            if(stripeSubscription.encounteredError){
              setStripeLoading(false);
              setLoading(false);
              setStripeErrorMessage(stripeSubscription.errorMessage)
              toast.error(`There was an error processing your request. ${stripeSubscription.errorMessage}`)
            }
            else if(!stripeSubscription.encounteredError) {
              
              toast.success('Payment successful!')
              setLoadingMessage('Upgrade successful...')
              createFinicityCustomerAuto(stripeSubscription.subscriptionId);
            
 
              
            }
 
      
 }

 async function bypassCustomerAuto(){
  let subscriptionId = 'Sample'
  let url = testMode ? 'createFinCustomerTest' : 'createFinCustomer'
  setLoadingMessage('Contacting Finicity...')
  let createFinMethod = httpsCallable(functions,url);
    
  let userName = generateRandomString(10)
  let customerId = await createFinMethod({username: userName}).then((res) => {
      return res.data.customerId;
  }).catch((err) => {
      
      return false;
  });
  
  if(customerId !== false){
  await updateUserDocument({finCustomerId:customerId,isPremiumUser:true,subscriptionMethod:'Web',stripeSubscriptionId:subscriptionId})
  dispatch({type:'UPDATE_USER_DATA',payload:{...state.userData,finCustomerId:customerId,isPremiumUser:true,budgetMethod:'Auto',stripeSubscriptionId:subscriptionId,
    subscriptionMethod:'Web'}})
 
    getFinUrlForUpgradeFromManualToAuto(customerId)
  }
  else {
    setLoading(false);
    toast.error('There was an error processing your request.')
  }
  } 


   async function createFinicityCustomerAuto(subscriptionId){
    
    let url = testMode ? 'createFinCustomerTest' : 'createFinCustomer'
    setLoadingMessage('Contacting Finicity...')
    let createFinMethod = httpsCallable(functions,url);
      
    let userName = generateRandomString(10)
    let customerId = await createFinMethod({username: userName}).then((res) => {
        return res.data.customerId;
    }).catch((err) => {
        
        return false;
    });
    
    if(customerId !== false){
    await updateUserDocument({finCustomerId:customerId,isPremiumUser:true,subscriptionMethod:'Web',stripeSubscriptionId:subscriptionId})
    dispatch({type:'UPDATE_USER_DATA',payload:{...state.userData,finCustomerId:customerId,isPremiumUser:true,budgetMethod:'Auto',stripeSubscriptionId:subscriptionId,
      subscriptionMethod:'Web'}})
   
      getFinUrlForUpgradeFromManualToAuto(customerId)
    }
    else {
      setLoading(false);
      toast.error('There was an error processing your request.')
    }
    } 



    async function getFinUrlForUpgradeFromManualToAuto(finCustomerId){

      let retrieveUrl = httpsCallable(functions,'refreshPromiseCustomUrl');
      let urlResult = await retrieveUrl({customerId: finCustomerId}).then(function(result){
          return result;
      }).catch(function(error){
          console.log('error generating fin url');
          return false;
      })
      
      if(urlResult.data.theUrl === 'error getting url'){

          toast.error('Budget Even has encountered an error')
      }
      else {

        let theUrl = String(urlResult.data.theUrl);
        navigate('../finicityaawb',{state:{urlToUse: theUrl,finCustomerId:finCustomerId}});
    
    }
    }

   async function byPassTest(){
     // dispatch({type:'UPDATE_USER_DATA',payload:{...state.userData,isPremiumUser:true, budgetMethod:'Auto'}})
      //await updateUserDocument({isPremiumUser:true,subscriptionMethod:'Web'})
      createFinicityCustomerAuto();
    }



  if(loading){
    return (
        <BackDropLoading loadingMessage={loadingMessage} />
    )
  }
  else {

    return(
      <Box pt={2} pb={4} style={{marginLeft: isDownLarge ? 75 : 0}}>
          <LogoHeader />

      <Grid container style={{justifyContent:'center',marginTop:25,alignSelf:'center'}}>
          <div style={{width:550, height:750,backgroundColor:'white',borderRadius:5,border: '1px solid grey'}}>  
              <div style={{width:550,height:100,alignContent:'center',backgroundColor: theme.palette.primary.main,marginBottom:15}}>
                    <Typography variant='h5' style={{textAlign:'center',color:'white',fontWeight:'bold'}}>Budget Even: Auto</Typography>
              </div>
          
              <Typography style={{color:'red',fontStyle:'italic'}}>{stripeErrorMessage}</Typography>
              <div style={{marginLeft:10,marginRight:10,marginTop:25}}>
              <Typography style={{fontWeight:500}}>Card information</Typography>
              <CardElement />
              </div>

              <div style={{margin:10}}>
              <Typography style={{fontWeight:500}}>Name on card</Typography>
              <AppTextField  onChange={(e) => setFirstName(e.target.value)}fullWidth error={firstNameError} helperText='' />
               </div>

               <div style={{margin:10}}>
              <Typography style={{fontWeight:500}}>Zip</Typography>
              <AppTextField  onChange={(e) => setZip(e.target.value)} error={zipError}/>
               </div>

               <Divider style={{marginTop:25}} />
               <Typography style={{marginLeft:10,marginRight:10,fontWeight:500,marginTop:25}}>Order Summary</Typography>


  <Typography style={{marginLeft:10,marginRight:10,marginBottom:10}}>By clicking the subscribe button below, you agree to subscribe to the Budget Even Auto mode for a monthly payment of $4.99.
  You may cancel your subscription at any time.</Typography>


      <Box paddingX={3}>
                <FlexBetween my={2}>
                  <H3>Today's Total</H3>
                  <H3 color="primary.main">$4.99</H3>
                </FlexBetween>

              <LoadingButton disabled={canSubmit ? false : true} fullWidth  onClick={() => checkFields()}  loading={stripeLoading}  variant="contained">
                  Subscribe
                </LoadingButton>

                <LoadingButton  fullWidth  onClick={() => bypassCustomerAuto()}  loading={stripeLoading}  variant="contained">
               Bypass
              </LoadingButton>

      </Box>
          </div>
        </Grid>
      <BottomSpacer />
      </Box>
    )

  
}
 
}

export default UpdatedCheckOutInterior;


//Run through updated tests again
//make sure the userData gets updated to no cash flow set false after generting
//Add in mobile disclaimer on routes?
