import BudgetEvenLogo from '../../landingpage/landingimages/budgeteven.svg'
import React from 'react'
import {useTheme,AppBar,Container,useMediaQuery,styled,Typography,Toolbar } from '@mui/material';
import { useNavigate } from "react-router-dom";
import useWindowSize from 'hooks/useWindowSize';

export default function BlogToolBar() {

    const theme = useTheme();
    const MenuList = styled("ul")(({theme}) => ({margin: 0,padding: 0,listStyle: "none",[theme.breakpoints.down("sm")]: { "& a": {display: "block",marginTop: "1rem"}}}));

    const downMd = useMediaQuery(theme => theme.breakpoints.down("md"));
    const { height} = useWindowSize()
    const navigate = useNavigate();


  return (
    <AppBar position="fixed" sx={{boxShadow: 2,minHeight: 64,justifyContent: "center",backgroundColor: "white"}}>
    <Container>
      <Toolbar sx={{justifyContent: "space-between",pl:0,pr:0}}>
      <div onClick={() => navigate('./')} ><img src={BudgetEvenLogo} width={200} alt="Logo" /></div>
  
            {downMd || height < 400 ? null : <MenuList style={{display:'flex', flexDirection: 'row', padding:15}}>
            <Typography    onClick={() => navigate('/login')} style={{cursor:'pointer', fontWeight:'bold', color: theme.palette.primary.main, marginBottom:10,marginLeft:10, marginRight:10, }}>Login</Typography>
            <Typography    onClick={() => navigate('/register')} style={{cursor:'pointer', fontWeight:'bold', color: theme.palette.primary.main, marginBottom:10,marginLeft:10, marginRight:10, }}>Sign up</Typography>
            <Typography to="Learn" onClick={() => navigate('/blog')} style={{cursor:'pointer', fontWeight:'bold', color: theme.palette.primary.main, marginBottom:10,marginLeft:10, marginRight:10, }}>Learn</Typography>

          </MenuList>}
          </Toolbar>
        </Container>
  </AppBar>

  )
}
