import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import {Button, Card, Grid, useTheme, List, ListItem, ListItemIcon, ListItemText, CardHeader, CardContent, useMediaQuery } from "@mui/material";
import { CurrencyExchange } from '@mui/icons-material';
import {H5} from "uicomponents/Typography";
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import React from "react";


const NotPremiumCard = ({handleTopPartClick}) => {
  
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid container direction='row' justifyContent='center' style={{paddingTop:25}}>
    <Card sx={{width: downMd ? 400 : 800 }}>
    <CardHeader title='Accounts all in One Place' titleTypographyProps={{color:'white', fontWeight:'bold', textAlign:'center'}} sx={{backgroundColor: theme.palette.primary.dialogBackground}} />
    <CardContent style={{alignContent:'center', justifyContent:'center', alignItems:'center', display:'flex', flexDirection:'column'}}>
    
    <Grid container direction='row' justifyContent='center'>
        <Grid item>
        <CurrencyExchange sx={{fontSize: 100,marginBottom: 2,color: theme.palette.success.main}}/>
        </Grid>
    </Grid>
    
    
    <Grid container direction='row' justifyContent='center' alignContent='center' alignItems='center' style={{width:400}}>
    <Grid item md={12} lg={12}>
    <List>
          <ListItem>
              <ListItemIcon>
                  <LooksOneIcon style={{color: theme.palette.secondary.yellow}} />
              </ListItemIcon>
              <ListItemText sx={{color: theme.palette.text.disabled, fontWeight:'bold'}}
                            primary={<H5>Connect your credit cards, checking, savings, and more!</H5>}/>
          </ListItem>

          <ListItem>
              <ListItemIcon>
                  <LooksTwoIcon style={{color: theme.palette.secondary.yellow}} />
              </ListItemIcon>
              <ListItemText sx={{color: theme.palette.text.disabled, fontWeight:'bold'}}
              primary={<H5>View recent transactions, payment due dates, and balances.</H5>}/>
          </ListItem>
    
          <ListItem>
              <ListItemIcon>
                  <Looks3Icon style={{color: theme.palette.secondary.yellow}} />
              </ListItemIcon>
              <ListItemText sx={{color: theme.palette.text.disabled, fontWeight:'bold'}}
              primary={<H5>Keep an eye on all of your accounts from a single home page.</H5>}/>
          </ListItem>
      </List>
    </Grid>
  
    </Grid>
    
    <Button variant='contained' startIcon={<AccountBalanceIcon />}  
    onClick={() => handleTopPartClick()} 
    style={{color:'white',backgroundColor: theme.palette.primary.dialogBackground, fontWeight:'bold', marginRight:15}}>Connect Account</Button>
    </CardContent>
    </Card>
    </Grid>
  );
};

export default NotPremiumCard;
