import { Card,styled,} from "@mui/material"; 

export const TopAreaWrapper = styled(Card)(({
    theme
  }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    flexWrap: "wrap",
    [theme.breakpoints.down(700)]: {
      "& h2": {
        paddingTop: "1rem"
      },
      "& img": {
        display: "none"
      }
    }
  }));
