import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import BudgetEvenLogo from '../../landingpage/landingimages/budgeteven.svg'
import { Card, CardContent,Grid, Button,useTheme, Typography,styled,Box,Checkbox,Table,TableRow,TableBody,TableCell,TableHead} from '@mui/material'
import FindYourBank from './FindYourBank.png'
import FlexBox from 'uicomponents/FlexBox';
import { getTodaysEpoch,formatTransactionDate, getTwoDaysFromToday } from 'utils/Helpers';
import { H5,H2 } from 'uicomponents/Typography';
import './MyCarousel.css'; 
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import React,{useState} from 'react'
import SmallSingleYellow from './smallsingleyellow.svg'



const Wrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  padding: "0 1.5rem",
  paddingTop: "1rem"
}));

export default function AutoCard() {
    const theme = useTheme();

    const [currentStep,setCurrentStep] = useState(0)
  
    function determineColumnWidth(){
      return 125
    }


    const AnalysisCard = () => {
      return (
        <Table style={{width:300}}>
        <TableHead sx={{backgroundColor: theme.palette.primary.dialogBackground}}>
          <TableRow>
          <TableCell  variant='caption' style={{color:'white', fontWeight:'bold'}}>Budget Group</TableCell>
          <TableCell   variant='caption' style={{color:'white', cursor:'pointer', fontWeight:'bold'}}>QTY{'(#)'}</TableCell>
          <TableCell  variant='caption' style={{color:'white', cursor:'pointer', fontWeight:'bold'}}>Total</TableCell>
          </TableRow>

        </TableHead>
      <TableBody style={{backgroundColor:'white', }}>
      <TableRow >
              <TableCell><Typography variant='caption'>Starbucks</Typography></TableCell>
              <TableCell><Typography variant='caption'>5</Typography></TableCell>
              <TableCell> <Typography variant='caption' style={{fontWeight:'bold',color:  theme.palette.error.main}}>{new Intl.NumberFormat('us-US',{style:'currency', currency:'USD'}).format(53.47)}</Typography></TableCell>
            </TableRow>

            <TableRow >
              <TableCell><Typography variant='caption'>McDonalds</Typography></TableCell>
              <TableCell><Typography variant='caption'>3</Typography></TableCell>
              <TableCell> <Typography variant='caption' style={{fontWeight:'bold',color: theme.palette.error.main}}>{new Intl.NumberFormat('us-US',{style:'currency', currency:'USD'}).format(27.10)}</Typography></TableCell>
            </TableRow>

      </TableBody>
      </Table>
      )
    }

    const NetWorthCard = () => {
      return (
        <Card   sx={{boxShadow: 4,width:400,minHeight:200,height: 'auto',}}>
            <Grid container>
              <Grid item xs={12}>
                <Wrapper>
                  <FlexBox alignItems="center">
          <img style={{marginLeft:-25}} src='https://prod-carpintero-branding.s3.us-west-2.amazonaws.com/171237/logo.svg' height={40} alt=''></img>
                  </FlexBox>
                </Wrapper>
                <Box>
   
   <Grid container direction='row' justifyContent='center' style={{backgroundColor: theme.palette.secondary.assetHeader, 
    color:'white', paddingTop:10, paddingBottom:10, marginTop:10,  paddingLeft: 10}}  >
       <Grid item xs={2} sm={2} md={3} lg={3} xl={3}  sx={{  fontSize: 12,fontWeight:600,fontWeight: 600,}}>
          Title
       </Grid>


       <Grid item xs={2} sm={2} md={3} lg={2} xl={2}  sx={{fontSize: 12,fontWeight: 600,}}>
          Amount
       </Grid>


     </Grid>

     <Grid container direction='row' justifyContent='center' mt={2} mb={2} style={{paddingLeft:10}} >
          <Grid item xs={2} sm={2} md={3} lg={3} xl={3}  sx={{fontSize: 12,fontWeight:600}}>
          401(k)
       </Grid>
       <Grid item xs={2} sm={2} md={3} lg={2} xl={2}  sx={{fontSize: 12,fontWeight: 600}}>
        {new Intl.NumberFormat('us-US',{style:'currency', currency:'USD'}).format(53000)}
       </Grid>
    </Grid>


    <Grid container direction='row' justifyContent='center' mt={2} mb={2} style={{paddingLeft:10}} >
          <Grid item xs={2} sm={2} md={3} lg={3} xl={3}  sx={{fontSize: 12,fontWeight:600}}>
          Roth IRA
       </Grid>
       <Grid item xs={2} sm={2} md={3} lg={2} xl={2}  sx={{fontSize: 12,fontWeight: 600}}>
        {new Intl.NumberFormat('us-US',{style:'currency', currency:'USD'}).format(15000)}
       </Grid>

    </Grid>  

 </Box>
              </Grid>
            </Grid>
          </Card>
      )
    }

    const CashFlowCard = () => {

      return (
        <Grid container direction='column' style={{maxWidth:1000}}>
        <Grid item>
                  <Grid container direction='row' style={{width:'auto', marginTop:10, marginBottom:10,
                  backgroundColor: theme.palette.primary.dialogBackground, padding:5, borderRadius:5}}>
                      <Grid item>
                       <Typography style={{fontWeight:'bold', color:'white'}}>{formatTransactionDate(getTodaysEpoch())}</Typography>
                 
                      </Grid>
                   
                    <Grid item style={{marginLeft:'auto', fontWeight:'bold', color:'white'}}>
                 <Typography style={{fontWeight:'bold'}}>  {new Intl.NumberFormat('us-US', {style: 'currency', currency:'USD'}).format(7500)}  </Typography>
                  </Grid>
                  </Grid>
          </Grid>

          <Grid  container direction='row' style={{width:'auto', margin:5, padding:5}}>
                     <Grid item xs={8} style={{textAlign:'left'}}>
                       <Typography variant='body2' style={{color: theme.palette.text.primary, fontWeight:'500'}}>Mortgage</Typography>
                     </Grid>
                     <Grid item xs={4} style={{marginLeft:'auto', textAlign:'right'}}>
                       <Typography  style={{fontWeight:'bold',color:  theme.palette.error.main}} variant='body2'>{new Intl.NumberFormat('us-US', {style:'currency', currency:'USD'}).format(1300.84)}</Typography>
                     </Grid>
                 </Grid>

                 <Grid  container direction='row' style={{width:'auto', margin:5, padding:5}}>
                     <Grid item xs={8} style={{textAlign:'left'}}>
                       <Typography variant='body2' style={{color: theme.palette.text.primary, fontWeight:'500'}}>Payroll</Typography>
                     </Grid>
                     <Grid item xs={4} style={{marginLeft:'auto', textAlign:'right'}}>
                       <Typography  style={{fontWeight:'bold',color:  theme.palette.error.main}} variant='body2'>{new Intl.NumberFormat('us-US', {style:'currency', currency:'USD'}).format(1200.35)}</Typography>
                     </Grid>
                 </Grid>
  
                 <Grid item>
                  <Grid container direction='row' style={{width:'auto', marginTop:10, marginBottom:10,
                  backgroundColor: theme.palette.primary.dialogBackground, padding:5, borderRadius:5}}>
                      <Grid item>
                       <Typography style={{fontWeight:'bold', color:'white'}}>{getTwoDaysFromToday()}</Typography>
                 
                      </Grid>
                   
                    <Grid item style={{marginLeft:'auto', fontWeight:'bold', color:'white'}}>
                 <Typography style={{fontWeight:'bold'}}>  {new Intl.NumberFormat('us-US', {style: 'currency', currency:'USD'}).format(7399.51)}  </Typography>
                  </Grid>
                  </Grid>
          </Grid>

      </Grid>
      )
    }
    
    const BudgetRecapCard = () => {
      return (
        <Card sx={{ minWidth: 400 }}>
        <CardContent>
        <img style={{alignSelf:'center',width:200}} src={BudgetEvenLogo}  alt="Logo" />
          <Typography variant="h5" component="div">
          <Typography style={{fontWeight:'bold', color: theme.palette.primary.fiverGrayText,maxWidth:400}} component="div">
          You have spent $2,500 so far this month which is 60% of your budgeted amount. You have overspent in 3 line items.  Reallocate funds from other line items with excess amounts to correct.
          </Typography>

          </Typography>
         
        </CardContent>
    
      </Card>
      )
    }

    const FoodCard = () => {
      const pod = {title:'Food',data:[{description:'Groceries',plannedValue:500.00,spentValue:150.00},
        {description:'Dining Out',plannedValue:100.00,spentValue:19.00}
      ]}
      return (
        <Card style={{width:500,alignSelf:'center',padding:5}}>
            <Grid container>
              <Grid item xs={12}>
            <Wrapper>
                <FlexBox alignItems='center'>
                <H5  style={{cursor:'pointer',color:'black'}}>Food</H5>
                </FlexBox>
       
            </Wrapper>
              </Grid>
            </Grid>

            <Grid container direction='row' justifyContent='center' style={{backgroundColor: theme.palette.primary.main, color:'white', paddingTop:10, paddingBottom:10, marginTop:10}}  >
          <Grid item xs={2} sm={2} md={3} lg={3} xl={3}  sx={{  fontSize: 12,
              fontWeight: 600,
              width: determineColumnWidth()
            }}>
              Description

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{  fontSize: 12,
              fontWeight: 600,
              width: determineColumnWidth()
            }}>
              Planned

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}  sx={{  fontSize: 12,
              fontWeight: 600,
              width: determineColumnWidth()
            }}>
          
            Spent

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{  fontSize: 12,
              fontWeight: 600,
              width: determineColumnWidth()
            }}>
             Remaining

          </Grid>


        </Grid>

        {pod.data.map((item,i) => {
            return(
             
    
                <Grid key={i.toString()} container direction='row' justifyContent='center' mt={2} >
                  <Grid item xs={2} sm={2} md={3} lg={3} xl={3}  sx={{  fontSize: 12,
              fontWeight: 600,
              width: determineColumnWidth()
            }}>
             {item.description}

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}  sx={{  fontSize: 12,
              fontWeight: 600,
              width: determineColumnWidth()
            }}>
              {new Intl.NumberFormat('us-US',{style:'currency', currency:'USD'}).format(item.plannedValue)}

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}  sx={{  fontSize: 12,
              fontWeight: 600,
              width: determineColumnWidth(),
         
              color: theme.palette.error.main
            }}>
            {new Intl.NumberFormat('us-US',{style:'currency', currency:'USD'}).format(item.spentValue)}

          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}  sx={{  fontSize: 12,
              fontWeight: 600,
              width: determineColumnWidth(),
              color: theme.palette.success.main
            }}>


             {new Intl.NumberFormat('us-US',{style:'currency', currency:'USD'}).format(item.plannedValue - item.spentValue)}

          </Grid> 

   


      
                  </Grid>
            
            
            )
          })}
        </Card>
      )
    }

    const TransactionCard  = () => {
             return(
              <Grid  container  style={{marginTop: 10, width:300, alignSelf:'center', padding:10, color:'black', border: '2px solid #FD396D', borderRadius:5}}>
              <Grid container direction='row'>
                      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                         <Checkbox  style={{padding:0,cursor:'pointer'}} 
                          />
                     </Grid>

                     <Grid item xs={2} sm={2} md={2} lg={2} xl={2} style={{marginLeft:3}}>
                      <Typography style={{fontWeight:'700', color: theme.palette.primary.main}}>{formatTransactionDate(getTodaysEpoch())}</Typography>
                      </Grid>
                   

                      <Grid item xs={8} sm={8} md={8} lg={8} xl={8} style={{marginLeft:'auto', textAlign:'right'}}>
                      <Typography style={{fontWeight:'bold',color: theme.palette.error.main}}> 
                      {new Intl.NumberFormat('us-US',{style:'currency', currency:'USD'}).format(-21.47)}</Typography>
                     
                      </Grid>
                      
              </Grid>

              <Grid container direction='row' style={{padding:3}}>
                  

                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                      <Typography variant='caption' 
                       style={{color: theme.palette.text.primary, cursor:'pointer', fontWeight:'bold'}}>Walmart</Typography>
                         
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                      <Typography variant='caption'
                       style={{color: theme.palette.text.primary, fontWeight:'bold'}}>walmart debit</Typography>
                     
                      </Grid>
                    
              </Grid>
       
             
            </Grid>
             )
    }

    function determineDisplay(){
      switch(currentStep){
        case 0:
          return (
            <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
            <div style={{display:'flex', flexDirection:'column',alignItems:'center',marginBottom:10}}><H2 style={{fontWeight:'bold', color: theme.palette.primary.dialogBackground}}>How does it work?</H2>
            <img src={SmallSingleYellow} style={{width:200,marginTop:-20}} alt=''></img> 
            </div>
          <FoodCard />
          <H5 style={{marginTop:25, textAlign:'center'}}>Get started by adding your own custom budget groups and line items.</H5>
     
      </div>
          )

          case 1:
            return(
              <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
    
                  <div style={{display:'flex', flexDirection:'column',alignItems:'center',marginBottom:10}}><H2 style={{fontWeight:'bold', color: theme.palette.primary.dialogBackground}}>Connect your Accounts</H2>
                        <img src={SmallSingleYellow} style={{width:200,marginTop:-20}} alt=''></img> 
                  </div>
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                  <img style={{height:302,width:194}} src={FindYourBank} alt="Slide 2" />
                  </div>
                
                  <H5 style={{marginTop:25, textAlign:'center'}}>Use our partner, Finicity by MasterCard, to connect your credit cards, checking accounts, and more.</H5>
              </div>
              
           
            )
            case 2:
              return (
                <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                <div style={{display:'flex', flexDirection:'column',alignItems:'center',marginBottom:10}}><H2 style={{fontWeight:'bold', color: theme.palette.primary.dialogBackground}}>Auto-Download Transactions</H2>
                <img src={SmallSingleYellow} style={{width:200,marginTop:-20}} alt=''></img> 
                </div>
                <TransactionCard />
              <H5 style={{marginTop:25, textAlign:'center'}}>Your transactions will be auto-downloaded for you track to a Budget Group and Line Item.</H5>
         
          </div>
              )

              case 3:
                return (
                  <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                  <div style={{display:'flex', flexDirection:'column',alignItems:'center',marginBottom:10}}><H2 style={{fontWeight:'bold', color: theme.palette.primary.dialogBackground}}>Budget Even Learns</H2>
                  <img src={SmallSingleYellow} style={{width:200,marginTop:-20}} alt=''></img> 
                  </div>
                  <TransactionCard />
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'center',margin:5}}>
                  <ArrowDownwardIcon />
                  </div>
             
                  <FoodCard />
                <H5 style={{marginTop:25, textAlign:'center'}}>As you track your transactions, Budget Even will learn your preferences and begin tracking for you.</H5>
           
            </div>
                )

                case 4:
                  return (
                    <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                    <div style={{display:'flex', flexDirection:'column',alignItems:'center',marginBottom:10}}><H2 style={{fontWeight:'bold', color: theme.palette.primary.dialogBackground}}>Auto-Scanning</H2>
                    <img src={SmallSingleYellow} style={{width:200,marginTop:-20}} alt=''></img> 
                    </div>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                      <BudgetRecapCard />
                    </div>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                  <H5 style={{marginTop:25,width:600, textAlign:'center'}}>Each day you login, Budget Even will scan for and track your new transactions according to your preferences.  A budget recap will be presented that you can share with a partner.</H5>
                    </div>
              </div>
                  )


                  case 5:
                    return (
                      <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                      <div style={{display:'flex', flexDirection:'column',alignItems:'center',marginBottom:10}}><H2 style={{fontWeight:'bold', color: theme.palette.primary.dialogBackground}}>Cash Flow Projection</H2>
                      <img src={SmallSingleYellow} style={{width:200,marginTop:-20}} alt=''></img> 
                      </div>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                        <CashFlowCard />
                      </div>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                    <H5 style={{marginTop:25,width:600, textAlign:'center'}}>A 12-month cash flow projection will also be created that you can customize.  Add your own line items, edit values, and plan ahead.</H5>
                      </div>
                </div>
                    )

                    case 6:
                      return (
                        <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                        <div style={{display:'flex', flexDirection:'column',alignItems:'center',marginBottom:10}}><H2 style={{fontWeight:'bold', color: theme.palette.primary.dialogBackground}}>Net Worth</H2>
                        <img src={SmallSingleYellow} style={{width:200,marginTop:-20}} alt=''></img> 
                        </div>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                        <NetWorthCard />
                        </div>
                      <H5 style={{marginTop:25, textAlign:'center'}}>Your net worth balance sheet will automatically update with your connected accounts.</H5>
                 
                  </div>
                      )


                      case 7:
                        return (
                          <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                          <div style={{display:'flex', flexDirection:'column',alignItems:'center',marginBottom:10}}><H2 style={{fontWeight:'bold', color: theme.palette.primary.dialogBackground}}>Analysis and More</H2>
                          <img src={SmallSingleYellow} style={{width:200,marginTop:-20}} alt=''></img> 
                          </div>
                          <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                          <AnalysisCard />
                          </div>
                        <H5 style={{marginTop:25, textAlign:'center'}}>Analyze your budget by group, line item, and merchant to optimize your savings.</H5>
                   
                    </div>
                        )


                    

          default:
            return (
              <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
              <div style={{display:'flex', flexDirection:'column',alignItems:'center',marginBottom:10}}><H2 style={{fontWeight:'bold', color: theme.palette.primary.dialogBackground}}>How does it work?</H2>
              <img src={SmallSingleYellow} style={{width:200,marginTop:-20}} alt=''></img> 
              </div>
            <FoodCard />
            <H5 style={{marginTop:25, textAlign:'center'}}>Get started by adding your own custom budget groups and line items.</H5>
       
        </div>
            )
      }
    }

    function handleNextStep(){
      if(currentStep < 7){
        setCurrentStep((currentStep + 1))
      }
    }

    function handlePreviousStep(){
      if(currentStep > 0){
        setCurrentStep((currentStep - 1))
      }
    }

return(
  <div>
{determineDisplay()}
<div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
  {currentStep > 0 ? <Button style={{color:theme.palette.primary.main,marginTop:5}} onClick={()=> handlePreviousStep()}>Previous</Button> :  null}
  {currentStep < 7 ? <Button style={{color:theme.palette.primary.main,marginTop:5}} onClick={()=> handleNextStep()}>Next</Button> : null}
</div>

  </div>

)
}

