import { Ellipsis} from 'react-spinners-css'
import { Grid,Typography,useTheme,Backdrop } from '@mui/material'
import React from 'react'

export default function BackDropLoading({loadingMessage}) {
    const theme = useTheme();

  return (
    <div>
    <Backdrop open={true} style={{zIndex:1400,backgroundColor:'white'}}>
    <Grid container direction='column'  justifyContent='center' alignItems='center'>
      <Grid item>
      <Ellipsis color={theme.palette.primary.main} />
      </Grid>
 <Grid item style={{paddingTop:5}}>
    <Typography style={{color:theme.palette.primary.fiverGrayText, fontWeight:'bold'}}>{loadingMessage}</Typography>
  </Grid> 
  
    </Grid>
    </Backdrop> 
    </div>

  )
}
