import BudgetEvenLogo from '../../landingpage/landingimages/budgeteven.svg'
import { Grid } from '@mui/material'
import React from 'react'

export default function LogoHeader() {
  return (
    <Grid container direction='row' justifyContent='center' mt={5}>
    <Grid item>
    <img style={{alignSelf:'center',width:200,height:'auto'}} src={BudgetEvenLogo}  alt="Logo" />
    </Grid>
</Grid>
  )
}
